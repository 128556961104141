import { useAuthApi } from "modules/api-client"
import { WaterfrontRedirectParams } from "modules/api-client/src/auth/requests/waterfront-redirect-params"
import { useAuth } from "modules/auth"
import getConfig from "next/config"
import { useRouter } from "next/router"
import { ReactNode, useEffect, useState } from "react"
import Spinner from "ui-kit/elements/spinner"

export default function Home (): ReactNode {

    const query = useRouter().query as {token?: string, user?: string, token_id?: string}
    const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { defaultRoute: string, loginRoute: string } }
    const { push } = useRouter()
    const { loginAsRequest, loginLighthouseRequest } = useAuthApi()
    const { login, user } = useAuth()
    const [loading, setLoading] = useState( false )
    const [cameFromLighthouse, setCameFromLighthouse] = useState( false )

    useEffect( () => {
        // This is an admin user that uses Nova LoginAs other user.
        if ( query.token && query.user && ! loading && ! query.token_id ) {
            setLoading( true )
            loginAsRequest( { user: query.user, token: query.token } ).then( response => {
                if ( response.success && response.data !== undefined ) {
                    login( response.data.user, response.data.token, undefined, response.data.original_user )
                    push( publicRuntimeConfig.defaultRoute )
                }
            } )
        }

        // This user comes from Lighthouse.
        if ( query.token_id && !loading && ! query.token && ! query.user ) {
            const waterfrontRedirectPayload: WaterfrontRedirectParams = {
                token_id: query.token_id,
            }
            setLoading( true )
            loginLighthouseRequest( waterfrontRedirectPayload ).then( response => {
                if ( response.success && response.data !== undefined ) {
                    setCameFromLighthouse( true )
                    login( response.data.user )
                    const defaultRouteGTM = `${publicRuntimeConfig.defaultRoute}?red_url=login.account.yourhosting.nl&red_ref=lighthouse`

                    push( defaultRouteGTM )
                } else {
                    window.location.replace( publicRuntimeConfig.loginRoute )
                }
            } )
        }

    }, [query, loading, push, publicRuntimeConfig, loginAsRequest, login, loginLighthouseRequest] )

    useEffect( () => {
        if ( user ) {
            let routeToPush: string = publicRuntimeConfig.defaultRoute
            if( cameFromLighthouse ) {
                routeToPush =`${routeToPush}?red_url=login.account.yourhosting.nl&red_ref=lighthouse`
            }
            push( routeToPush )
            return
        }

        if ( ! query.token_id || ! query.token ) {
            const fallback = setTimeout( () => window.location.replace( publicRuntimeConfig.loginRoute ), 3000 )
            return () => clearTimeout( fallback )
        }
    }, [ publicRuntimeConfig.defaultRoute, publicRuntimeConfig.loginRoute, push, query.token, query.token_id, user ] )

    return (
        <div className="my-32 text-center">
            <Spinner className="text-3xl text-brand-500" />
        </div>
    )
}
